import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

const Callback = () => {
  useEffect(() => {
    navigate('/app')
  }, [])

  return <></>
}

export default Callback
